<template>
    <section>
        <div class="container">
            <div class="row justify-content-center mt-2">
                <div class="col-md-10 mb-3">
                    <h3 class="titulo__">Skills</h3>
                </div>
            </div>
            <div class="row justify-content-center mb-4">
                <div class="col-md-6 col-lg-4">
                    <div class="contenedor__skills">
                        <p class="titulo__skill">Frontend</p>
                        <div class="row">
                            <div class="col-4 text-center">
                                <span class="justify-content-center">
                                    <img
                                        src="https://icongr.am/devicon/html5-plain.svg?size=128&color=3D3D3D"
                                        alt="HTML 5"
                                        class="icono__skill"
                                    />
                                    <p class="texto__centrado">HTML 5</p>
                                </span>
                            </div>
                            <div class="col-4 text-center">
                                <span class="justify-content-center">
                                    <img
                                        src="https://icongr.am/devicon/css3-plain.svg?size=128&color=3D3D3D"
                                        alt="CSS3"
                                        class="icono__skill"
                                    />
                                </span>
                                <p class="texto__centrado">CCS 3</p>
                            </div>
                            <div class="col-4 text-center">
                                <span class="justify-content-center">
                                    <img
                                        src="https://icongr.am/devicon/javascript-plain.svg?size=128&color=3D3D3D"
                                        alt="Java Script"
                                        class="icono__skill"
                                    />
                                </span>
                                <p class="texto__centrado">JS</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="contenedor__skills">
                        <p class="titulo__skill">Backend</p>
                        <div class="row">
                            <div class="col-4 text-center">
                                <span class="justify-content-center">
                                    <img
                                        src="https://icongr.am/devicon/php-plain.svg?size=148&color=3D3D3D"
                                        alt="PHP"
                                        class="icono__skill"
                                    />
                                </span>
                                <p class="texto__centrado">PHP</p>
                            </div>
                            <div class="col-4 text-center">
                                <span class="justify-content-center">
                                    <img
                                        src="https://icongr.am/devicon/laravel-plain.svg?size=148&color=3D3D3D"
                                        alt="Laravel"
                                        class="icono__skill"
                                    />
                                </span>
                                <p class="texto__centrado">Laravel</p>
                            </div>
                            <div class="col-4 text-center">
                                <span class="justify-content-center">
                                    <img
                                        src="https://icongr.am/devicon/mysql-plain.svg?size=128&color=3D3D3D"
                                        alt="MySql"
                                        class="icono__skill"
                                    />
                                </span>
                                <p class="texto__centrado">MySQL</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-8">
                    <div class="contenedor__skills">
                        <p class="titulo__skill">Frameworks y otras tecnologías</p>
                        <div class="row justify-content-center">
                            <div class="col-4 col-md-2 text-center">
                                <span class="justify-content-center">
                                    <img
                                        src="https://icongr.am/devicon/bootstrap-plain.svg?size=128&color=3D3D3D"
                                        alt="Bootstrap"
                                        class="icono__skill"
                                    />
                                </span>
                                <p class="texto__centrado">Bootstrap</p>
                            </div>
                            <div class="col-4 col-md-2 text-center">
                                <span class="justify-content-center">
                                    <img
                                        src="https://icongr.am/devicon/vuejs-plain.svg?size=128&color=3D3D3D"
                                        alt="VUE"
                                        class="icono__skill"
                                    />
                                </span>
                                <p class="texto__centrado">Vue.js</p>
                            </div>

                            <div class="col-4 col-md-2 text-center">
                                <span class="justify-content-center">
                                    <img
                                        src="https://icongr.am/devicon/jquery-plain.svg?size=128&color=3D3D3D"
                                        alt="jQuery"
                                        class="icono__skill"
                                    />
                                </span>
                                <p class="texto__centrado">jQuery</p>
                            </div>
                            <div class="col-4 col-md-2 text-center mt-mb-3">
                                <span class="justify-content-center">
                                    <img
                                        src="https://icongr.am/devicon/github-original.svg?size=128&color=3D3D3D"
                                        alt="GitHub"
                                        class="icono__skill"
                                    />
                                </span>
                                <p class="texto__centrado">GitHub</p>
                            </div>
                            <div class="col-4 col-md-2 text-center mt-mb-3">
                                <span class="justify-content-center">
                                    <img
                                        src="https://icongr.am/devicon/dot-net-plain-wordmark.svg?size=128&color=3D3D3D"
                                        alt="VB.NET"
                                        class="icono__skill"
                                    />
                                </span>
                                <p class="texto__centrado">VB.NET</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
                fill="#512DA8"
                fill-opacity="1"
                d="M0,32L34.3,32C68.6,32,137,32,206,58.7C274.3,85,343,139,411,170.7C480,203,549,213,617,192C685.7,171,754,117,823,80C891.4,43,960,21,1029,48C1097.1,75,1166,149,1234,186.7C1302.9,224,1371,224,1406,224L1440,224L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
            />
        </svg>
    </section>
</template>

<script>
export default {

}
</script>